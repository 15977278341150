import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-scroll';

import Layout from '../components/layout/layout';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
import SectionHero from '../components/wordPress/sections/sectionHero/sectionHero';
import Seo from '../components/seo/seo';

const HowWeTreatPage = ({ data }) => {
  // Map ACF content to variable
  const page = data.wpPage.pageHowWeTreat;

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/how-we-treat/`}
      />

      <>
        <SectionHero
          buttonDefaults={data.wp.globalContent.globalButtons.buttonSettings}
          sectionContent={page.howWeTreatHeader}
        />

        <Container fluid={true}>
          <Row className={`bg-light text-center`}>
            <Col
              xs={12}
              md={3}
              className={`d-flex`}
            >
              <Link
                to={`section0`}
                smooth={true}
                offset={-120}
                duration={500}
                className={`d-flex py-2 scroll-link`}
                style={{
                  alignSelf: 'center',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                Intensive Outpatient Programs
              </Link>
            </Col>
            <Col
              xs={12}
              md={3}
              className={`d-flex`}
            >
              <Link
                to={`section1`}
                smooth={true}
                offset={-120}
                duration={500}
                className={`d-flex py-2 scroll-link`}
                style={{
                  alignSelf: 'center',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                Outpatient Care Program
              </Link>
            </Col>
            <Col
              xs={12}
              md={3}
              className={`d-flex`}
            >
              <Link
                to={`section2`}
                smooth={true}
                offset={-120}
                duration={500}
                className={`d-flex py-2 scroll-link`}
                style={{
                  alignSelf: 'center',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                Medically Monitored Detoxification
              </Link>
            </Col>
            <Col
              xs={12}
              md={3}
              className={`d-flex`}
            >
              <Link
                to={`section3`}
                smooth={true}
                offset={-120}
                duration={500}
                className={`d-flex py-2 scroll-link`}
                style={{
                  alignSelf: 'center',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                Residential Treatment
              </Link>
            </Col>
          </Row>
        </Container>

        <section className={`pt-5 pb-4`}>
          <Container>
            <Row
              className={`justify-content-center mb-4`}
              id={`section0`}
              key={0}
            >
              <Col xs={11} md={8}>
                <h2>Intensive Outpatient Programs</h2>
                <p>Clean &amp; Sober Detox offers comprehensive addiction treatment that includes three levels of outpatient care to help clients develop the skills to build a healthy, fulfilling life in recovery. Our experienced staff of Board-Certified Physicians and mental health professionals work with you to develop a program based on your unique needs. Our Intensive Outpatient Programs provide treatment in a nurturing environment for clients who do not need 24-hour medical supervision, but who recognize the need for structure to have success in their recovery journey. Outpatient programs typically run 1-3 months, although the duration may vary. The three levels of outpatient care we offer are:</p>
                <ul>
                  <li><strong>Intensive Day Program</strong> is for people who need significant structure in their day to learn critical coping skills for sobriety and redirection of unhealthy cravings, activities, or behaviors. This level offers a six-hour treatment day six days per week.</li>
                  <li><strong>Intensive Outpatient Program</strong> is for people who are ready for a little more freedom to practice the skills they have learned with structured oversight. This level offers a three-hour treatment day three days per week.</li>
                  <li><strong>Outpatient Care</strong> is for people who have almost mastered their coping skills and need a check in to ensure they are incorporating their skills into their everyday life to manage stress and prevent relapse. This level offers three hours a day two days a week.</li>
                </ul>
                <p>We approach recovery as an ongoing process. We understand that the journey does not end after treatment and neither does our support for our clients. Many of our clients stay connected to the recovery community through our Alumni program.</p>
              </Col>
            </Row>

            <Row
              className={`justify-content-center mb-4`}
              id={`section1`}
              key={1}
            >
              <Col xs={11} md={8}>
                <h2>Outpatient Care Program</h2>
                <p>Our Outpatient Care Program gives clients the tools to manage stress and prevent relapse, with the flexibility to maintain a job and build healthy relationships and interests. Treatment includes:</p>
                <ul>
                  <li>Group therapy</li>
                  <li>On-site meetings</li>
                </ul>
                <p>As with our IOP program, we also support Medically Assisted Treatment (MAT) for clients who struggle with opioid use disorders.</p>
                <p>We approach recovery as an ongoing process. We understand that the journey doesn’t end after treatment, and neither does our support for clients. Many of our clients stay connected to the recovery community after completing their treatment through our alumni programs.</p>
              </Col>
            </Row>

            <Row
              className={`justify-content-center mb-4`}
              id={`section2`}
              key={2}
            >
              <Col xs={11} md={8}>
                <h2>Medically Monitored Detoxification </h2>
                <p>In the detoxification phase, a critical step toward full recovery, our experienced physicians and clinicians help clients safely manage withdrawal symptoms as they reduce, and eventually eliminate, drug and alcohol use. Although becoming physically sober is essential to recovery, it is often not enough for sustained recovery without further psychological and behavioral counseling.</p>
              </Col>
            </Row>

            <Row
              className={`justify-content-center mb-4`}
              id={`section3`}
              key={3}>
              <Col xs={11} md={8}>
                <h2>Residential Treatment</h2>
                <p>Inpatient, or residential treatment, allows clients to focus solely on their recovery in a safe, supportive, and nurturing environment. All of our rehab centers offer residential treatment options, each with a comfortable living arrangement and a personalized program of behavioral and group therapy. We also provide full medical support, with enough privacy and freedom to build confidence as you recover.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 998 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageHowWeTreat {
        howWeTreatHeader {
          fieldGroupName
          content
          htmlClass
          background {
            backgroundType
            backgroundImage {
              localFile {
                ...imageFluidLarge
              }
            }
            backgroundVideo {
              localFile {
                publicURL
              }
            }
            backgroundVideoCover {
              localFile {
                publicURL
              }
            }
          }
          buttonLayout {
            showButton
            overrideButtonSettings
            buttonSettings {
              buttonText
              linkType
              internalLink {
                ... on WpPage {
                  uri
                }
                ... on WpPost {
                  uri
                }
              }
              externalLink
              htmlClass
            }
          }
        }
        treatments {
          title
          content
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
      }
    }
  }
`;

export default HowWeTreatPage;
